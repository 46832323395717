import { useEffect, useState } from 'react';
import LineChart from '../helper/LineChart';
import { toast } from 'react-toastify';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { useFetchRentalIncome } from '../hooks/analysis.hooks';

interface BarChartDataItem {
  Year: number;
  TotalRent: number;
}

function YearlyAnalysis() {
  const { t } = useTranslation();
  const [yearlyData, setYearlyData] = useState<BarChartDataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const currencySymbol = localStorage.getItem('Currency') || '$';
  const errorMessages = new ErrorMessages();

  const {
    data: rentalIncome,
    isError: isRentalIncomeError,
    error: rentalIncomeError,
    isLoading: isRentalIncomeLoading,
  } = useFetchRentalIncome();

  useEffect(() => {
    if (isRentalIncomeLoading) {
      setLoading(true);
    }
  }, [isRentalIncomeLoading]);

  // Handle error state
  useEffect(() => {
    if (isRentalIncomeError) {
      ErrorHandler.handleError(rentalIncomeError, errorMessages, toast);
      setError(t('noData'));
      setLoading(false);
    }
  }, [isRentalIncomeError, rentalIncomeError]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        !rentalIncome ||
        !rentalIncome.Tenants ||
        rentalIncome.Tenants.length === 0
      ) {
        setLoading(false);
        return;
      }

      try {
        const tenants = rentalIncome.Tenants;
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 5;
        const endYear = currentYear;

        const yearlyData: BarChartDataItem[] = [];

        for (let year = startYear; year <= endYear; year++) {
          let totalRent = 0;

          tenants.forEach((tenant) => {
            const contractStart = new Date(tenant.StartDate);
            const contractEnd = new Date(tenant.EndDate);

            // Calculate the overlap period between the tenant's contract and the current year
            const start = new Date(
              Math.max(
                contractStart.getTime(),
                new Date(`${year}-01-01`).getTime()
              )
            );
            const end = new Date(
              Math.min(
                contractEnd.getTime(),
                new Date(`${year}-12-31`).getTime()
              )
            );

            if (start <= end) {
              const monthsActive = calculateMonths(start, end);
              const roomPrice = tenant.RoomPrice ?? 0;

              // Calculate yearly rent as an integer
              const yearlyRent = Math.floor((roomPrice / 12) * monthsActive);
              totalRent += yearlyRent;
            }
          });

          yearlyData.push({
            Year: year,
            TotalRent: totalRent,
          });
        }

        setYearlyData(yearlyData);
        setLoading(false);
      } catch (error) {
        ErrorHandler.handleError(error, errorMessages, toast);
        setError(t('noData'));
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalIncome, t]);

  const calculateMonths = (start: Date, end: Date) => {
    const startMonth = start.getMonth();
    const endMonth = end.getMonth();

    let months = endMonth - startMonth + 1;

    // Ensure months count is non-negative
    return Math.max(months, 0);
  };

  // Function to format number with commas and currency symbol
  const formatCurrency = (amount: number) => {
    return `${currencySymbol} ${amount.toLocaleString()}`;
  };

  // Prepare data for LineChart component
  const lineChartData = {
    labels: yearlyData.map((item) => item.Year.toString()),
    datasets: [
      {
        label: t('yearlyRentAmount'),
        data: yearlyData.map((item) => item.TotalRent),
        fill: false,
        borderColor: '#007bff',
      },
    ],
  };

  // Calculate total revenue and ensure it's an integer
  const totalRevenue = Math.floor(
    yearlyData.reduce((total, item) => total + item.TotalRent, 0)
  );

  // Display accumulated profit as revenue if expenses are not provided
  const accumulatedProfit = totalRevenue;

  // Calculate percentage revenue
  const percentageRevenue =
    totalRevenue > 0 ? Math.round((accumulatedProfit / totalRevenue) * 100) : 0;

  // Loading state UI
  if (loading) {
    return (
      <div className="overview-container">
        <Spinner />
      </div>
    );
  }

  // Error state UI
  if (error) {
    return (
      <div className="overview-container">
        <div className="container">
          <h2>{t('yearlyAnalysis')}</h2>
          <p>{t('noData')}</p>
        </div>
      </div>
    );
  }

  // Rendered UI when data is loaded successfully
  return (
    <div className="overview-container">
      <main className="yearlyAnalysis-content">
        <div className="container">
          <h2>{t('yearlyAnalysis')}</h2>
          <div className="chart-container">
            <LineChart data={lineChartData} />
          </div>

          {/* Additional data table */}
          <table>
            <thead>
              <tr>
                <th>{t('profitWithoutExpenses')}</th>
                <th>{t('revenue')}</th>
                <th>{t('percentageRevenue')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatCurrency(accumulatedProfit)}</td>
                <td>{formatCurrency(totalRevenue)}</td>
                <td>{percentageRevenue}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default YearlyAnalysis;
