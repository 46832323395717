import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/api';

export const useFetchAccessRequests = () => {
  return useQuery<any>({
    queryKey: ['accessRequests'],
    queryFn: api.getAccessRequests,
    refetchOnWindowFocus: true,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};

export const useFetchVersionUpdate = () => {
  return useQuery<any>({
    queryKey: ['versionUpdate'],
    queryFn: api.getVersionUpdate,
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
