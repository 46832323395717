import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { toast } from 'react-toastify';
import { Tenant } from '../utils/interfaces';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { useFetchRentalIncome } from '../hooks/analysis.hooks';

function ProfitAndLoss() {
  const { t } = useTranslation();
  const [totalRooms, setTotalRooms] = useState<number>(0);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const errorMessages = new ErrorMessages();

  const {
    data: rentalIncome,
    isError: isRentalIncomeError,
    error: rentalIncomeError,
    isLoading: isRentalIncomeLoading,
  } = useFetchRentalIncome();

  useEffect(() => {
    setLoading(isRentalIncomeLoading);
  }, [isRentalIncomeLoading]);

  useEffect(() => {
    if (isRentalIncomeError) {
      ErrorHandler.handleError(rentalIncomeError, errorMessages, toast);
      setError(t('noData'));
      setLoading(false);
    }
  }, [isRentalIncomeError, rentalIncomeError]);

  useEffect(() => {
    if (rentalIncome) {
      setTotalRooms(rentalIncome.TotalRooms);
      setTenants(rentalIncome.Tenants);
    }
  }, [rentalIncome, t]);

  // Function to calculate financial data (revenue, expenses, profit, and loss)
  const calculateFinancials = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: 5 },
      (_, index) => currentYear - 4 + index
    );

    // Function to calculate total revenue based on actual occupied rooms
    const calculateTotalRevenue = (year: number): number => {
      let totalRevenue = 0;
      tenants.forEach((tenant) => {
        const contractStart = new Date(tenant.StartDate);
        const contractEnd = new Date(tenant.EndDate);
        if (
          contractStart.getFullYear() <= year &&
          contractEnd.getFullYear() >= year
        ) {
          const startMonth =
            contractStart.getFullYear() === year
              ? contractStart.getMonth() + 1
              : 1;
          const endMonth =
            contractEnd.getFullYear() === year
              ? contractEnd.getMonth() + 1
              : 12;
          const monthsActive = endMonth - startMonth + 1;
          const roomPrice = tenant.RoomPrice ?? 0;

          // Revenue only from occupied rooms
          totalRevenue += (roomPrice / 12) * Math.max(monthsActive, 0);
        }
      });

      return Math.round(totalRevenue);
    };

    // Calculate total expenses
    const calculateTotalExpenses = (year: number): number => {
      let totalExpenses = 0;
      tenants.forEach((tenant) => {
        const contractStart = new Date(tenant.StartDate);
        const contractEnd = new Date(tenant.EndDate);
        if (
          contractStart.getFullYear() <= year &&
          contractEnd.getFullYear() >= year
        ) {
          const startMonth =
            contractStart.getFullYear() === year
              ? contractStart.getMonth() + 1
              : 1;
          const endMonth =
            contractEnd.getFullYear() === year
              ? contractEnd.getMonth() + 1
              : 12;
          const monthsActive = endMonth - startMonth + 1;
          const roomPrice = tenant.RoomPrice ?? 0;

          // Monthly expenses based on occupied months
          totalExpenses += (roomPrice * Math.max(monthsActive, 0)) / 12; // Monthly cost
        }
      });
      return Math.round(totalExpenses);
    };

    // Function to calculate potential loss from vacant rooms
    const calculatePotentialLoss = (year: number): number => {
      let totalPotentialLoss = 0;

      // Calculate the total number of vacant rooms and potential revenue
      const totalRooms = 10; // Assuming you know the total number of rooms
      const occupiedRoomsCount = tenants.filter((tenant) => {
        const contractStart = new Date(tenant.StartDate);
        const contractEnd = new Date(tenant.EndDate);
        return (
          contractStart.getFullYear() <= year &&
          contractEnd.getFullYear() >= year
        );
      }).length;

      const vacantRoomsCount = totalRooms - occupiedRoomsCount;

      // Calculate average room price
      const totalRoomPrices = tenants.reduce(
        (sum, tenant) => sum + (tenant.RoomPrice ?? 0),
        0
      );
      const averageRoomPrice = totalRoomPrices / (tenants.length || 1); // Prevent division by zero

      // Calculate potential revenue from vacant rooms
      totalPotentialLoss = vacantRoomsCount * averageRoomPrice; // Potential revenue lost

      return Math.round(totalPotentialLoss);
    };

    const revenueData = years.map(calculateTotalRevenue);
    const expensesData = years.map(calculateTotalExpenses);
    const potentialLossData = years.map(calculatePotentialLoss);

    const profitData = years.map((year, index) => {
      const revenue = revenueData[index];
      const expenses = expensesData[index];
      return Math.round(revenue - expenses); // Profit calculation
    });

    const lossData = years.map((year, index) => {
      const revenue = revenueData[index];
      const expenses = expensesData[index];
      const potentialLoss = potentialLossData[index];
      return Math.round(expenses + potentialLoss - revenue); // Adjusted loss calculation
    });

    return { years, revenueData, profitData, lossData };
  };

  const { years, revenueData, profitData, lossData } = calculateFinancials();

  const currencySymbol = localStorage.getItem('Currency');

  const data: ChartData<'line'> = {
    labels: years.map((year) => `${year - 1} - ${year}`),
    datasets: [
      {
        label: t('profit'),
        data: profitData,
        borderColor: 'green',
        backgroundColor: 'rgba(0, 128, 0, 0.1)',
        fill: false,
      },
      {
        label: t('revenue'),
        data: revenueData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.1)',
        fill: false,
      },
      {
        label: t('loss'),
        data: lossData,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        fill: false,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataset, parsed } = context;
            let label = dataset?.label || '';
            if (label) {
              label += ': ';
            }
            if (parsed.y !== null) {
              const roundedValue = Math.round(parsed.y);
              label +=
                currencySymbol +
                ' ' +
                new Intl.NumberFormat('en-US').format(roundedValue);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t('inMillions'),
        },
      },
    },
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="overview-container">
        <main className="profit-and-loss-content">
          <div className="container">
            <div className="profit-container p-4">
              <h2>{t('profitAndLossYear')}</h2>
              <p>{error}</p>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="overview-container">
      <main className="profit-and-loss-content">
        <div className="container">
          <div className="profit-container p-4">
            <h2>{t('profitAndLossYear')}</h2>
            <div className="chart-container">
              <Line data={data} options={options} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ProfitAndLoss;
